<template>
  <div>
    <b-row class="justify-content-around">
      <b-col cols="4">
        <b-row class="mb-1">
          <b-table :items="charges" :fields="fieldsCharges" ref="listCharges">
            <template #cell(created_at)="{ item }">
              {{ item.created_at | myGlobal }}
            </template>
            <template #cell(amount)="{ item }"> $ {{ item.amount }} </template>
          </b-table>
        </b-row>
        <b-row>
          <b-button :disabled="isResponsibleAccount" variant="primary" @click="openAddChargeModal"
            ><feather-icon icon="PlusIcon" /> Add Charge</b-button
          >
        </b-row>
      </b-col>
      <b-col cols="7">
        <b-row class="mb-1">
          <b-table
            :items="payments"
            :fields="fieldsPayments"
            ref="listPayments"
          >
            <template #cell(settlement_date)="{ item }">
              {{ item.settlement_date | myGlobal }}
            </template>
            <template #cell(amount)="{ item }"> $ {{ item.amount }} </template>

            <template v-slot:cell(status)="data">
              <div :style="`color: ${data.item.color}`">
                <feather-icon
                  @click="ApproveOrDeclineHeldTransaction(data.item)"
                  v-if="data.item.status_id == 4 || data.item.status_id == 19"
                  class="cursor-pointer text-warning mr-1"
                  icon="RefreshCcwIcon"
                  size="15"
                />
                <span v-if="data.item.status_id == 19" class="text-warning">
                  Held for review</span
                >
                <span v-else> {{ data.item.status }}</span>
              </div>
            </template>
          </b-table>
        </b-row>
        <b-row class="justify-content-end">
          <b-button :disabled="isResponsibleAccount" variant="primary" class="ml-1" @click="openCreditCardModal">
            <feather-icon icon="PlusIcon" /> Credit Cards
          </b-button>
          <!-- <b-button
            variant="primary"
            class="ml-1"
            :disabled="isResponsibleAccount"
            @click="openZeroPaymentModal"
          >
            <feather-icon icon="PlusIcon" /> Zero Payment
          </b-button> -->
          <b-button
            :disabled="balanceAccount >= 0 || isResponsibleAccount"
            variant="primary"
            class="ml-1"
            @click="openMonthlyPaymentModal"
          >
            <feather-icon icon="PlusIcon" /> Monthly Payment
          </b-button>
        </b-row>
      </b-col>
    </b-row>

    <total-payments
      :feeAndCharge="totalCharges"
      :payments="totalPayments"
      :balance="balanceAccount"
    />
    <add-charge-modal
      v-if="isActiveAddChargeModal"
      :title-add-charge="'ADD CHARGE'"
      @refresh="refreshCharges"
      @close="closeAddChargeModal"
    />
    <add-charge-modal-charges
      v-if="isActiveAddChargeModalCharges"
      :title-add-charge="'ADD CHARGE'"
      @refresh="refresh"
      @close="closeAddChargeModal"
    />
    <credit-card-modal
      v-if="isActiveCreditCardModal"
      :client="clientObj"
      @close="closeCreditCardModal"
    />
    <zero-payment-modal
      v-if="isActiveZeroPaymentModal"
      :zero-payment-transaction-id="zeroPaymentTransactionId"
      @refresh="refresh"
      @close="closeZeroPaymentModal"
    />
    <monthly-payment-modal
      v-if="isActiveMonthlyPaymentModal"
      @refreshTable="refresh"
      @close="closeMonthlyPaymentModal"
    />
  </div>
</template>
<script>
//vuex
import { mapActions, mapGetters } from "vuex";
//components
import TotalPayments from "../utils/TotalsPayments.vue";
//modals
import AddChargeModal from "@/views/commons/components/clients/dashboard/options/pay-module/modals/AddChargeModal.vue";
import AddChargeModalCharges from "@/views/commons/components/clients/dashboard/options/pay-module/modals/AddChargeModalCharges.vue";
import CreditCardModal from "@/views/commons/components/clients/dashboard/options/pay-module/modals/credit-card-modal/CreditCardModal.vue";
import ZeroPaymentModal from "@/views/commons/components/clients/dashboard/options/pay-module/modals/ZeroPaymentModal.vue";
import MonthlyPaymentModal from "@/views/commons/components/clients/dashboard/options/pay-module/modals/monthly-payment-modal/MonthlyPaymentModal.vue";
import ClientDashboardService from "@/views/commons/components/clients/dashboard/services/clients.dashboard.services";

export default {
  components: {
    TotalPayments,
    AddChargeModal,
    AddChargeModalCharges,
    CreditCardModal,
    ZeroPaymentModal,
    MonthlyPaymentModal,
  },
  data() {
    return {
      isActiveAddChargeModal: false,
      isActiveAddChargeModalCharges: false,
      isActiveCreditCardModal: false,
      isActiveZeroPaymentModal: false,
      isActiveMonthlyPaymentModal: false,
      fieldsCharges: [
        { key: "created_at", label: "Date" },
        { key: "fee", label: "Fee or Charge" },
        { key: "amount", label: "Amount" },
      ],
      fieldsPayments: [
        { key: "settlement_date", label: "Date" },
        { key: "type", label: "Type" },
        { key: "transaction_id", label: "Transaction ID" },
        { key: "amount", label: "Amount" },
        { key: "method_payment", label: "Method" },
        { key: "status", label: "Status" },
      ],
    };
  },
  watch: {
    RELOAD_TRANSACTIONS_STATUS() {
      this.refresh();
    },
  },
  methods: {
    async refreshCharges() {
      this.refresh();
      this.closeAddChargeModal();
    },
    async ApproveOrDeclineHeldTransaction(transaction, isAutoApprove = false) {
      try {
        this.addPreloader();
        const params = {
          transaction_id: transaction.transaction_id,
          action: "approve",
          user_id: this.currentUser.user_id,
        };
        const { data } =
          await ClientDashboardService.ApproveOrDeclineHeldTransaction(params);

        if (!isAutoApprove) {
          this.refresh();
          this.showGenericToast({
            position: "top-right",
            variant: data.variant,
            icon: data.icon,
            title: data.message,
            text: data.text,
          });
        }
      } catch (error) {
        this.showErrorSwal(error.response.data.message);
      } finally {
        this.removePreloader();
      }
    },
    openMonthlyPaymentModal() {
      this.isActiveMonthlyPaymentModal = true;
    },
    closeMonthlyPaymentModal() {
      this.isActiveMonthlyPaymentModal = false;
    },
    openZeroPaymentModal() {
      this.isActiveZeroPaymentModal = true;
    },
    closeZeroPaymentModal() {
      this.isActiveZeroPaymentModal = false;
    },
    openCreditCardModal() {
      this.isActiveCreditCardModal = true;
    },
    closeCreditCardModal() {
      this.isActiveCreditCardModal = false;
    },
    openAddChargeModal() {
      if (this.isBusinessModule) {
        this.isActiveAddChargeModalCharges = true;
        return;
      }
      this.isActiveAddChargeModal = true;
    },
    closeAddChargeModal() {
      if (this.isBusinessModule) {
        this.isActiveAddChargeModalCharges = false;
        return;
      }
      this.isActiveAddChargeModal = false;
    },
    async refresh() {
      try {
        this.addPreloader();
        await this.getCharges(this.accountId);
        await this.getPayments(this.accountId);
        this.$refs.listCharges.refresh();
        this.$refs.listPayments.refresh();
        await Promise.all([
          this.$store.dispatch("DebtSolutionClients/A_GET_CLIENTS", {
            id: this.$route.params.idClient,
          }),
        ]);
      } catch (error) {
        console.log(error);
      } finally {
        this.removePreloader();
      }
    },
    ...mapActions({
      getCharges: "ParagonClientDashboard/getCharges",
      getPayments: "ParagonClientDashboard/getPayments",
    }),
  },
  computed: {
    clientObj() {
      return {
        client_name: this.client,
        lead_id: this.leadId,
        program_name: this.program,
      };
    },
    totalCharges() {
      let total = 0;
      this.charges.map((el) => {
        total += parseFloat(el.amount);
      });
      return total;
    },
    totalPayments() {
      let total = 0;
      this.payments.map((el) => {
        total += parseFloat(el.amount);
      });
      return total;
    },
    balanceAccount() {
      return this.totalPayments - this.totalCharges;
    },
    ...mapGetters({
      charges: "ParagonClientDashboard/getCharges",
      payments: "ParagonClientDashboard/getPayments",
      leadId: "ParagonClientDashboard/getLeadId",
      client: "ParagonClientDashboard/getClient",
      program: "ParagonClientDashboard/getProgram",
      accountId: "ParagonClientDashboard/getAccountId",
      currentUser: "auth/currentUser",
      RELOAD_TRANSACTIONS_STATUS: "TransactionStatusStore/G_REFRESH",
      currentUser: "auth/currentUser",
    }),
    isBusinessModule() {
      return this.idModule === 3;
    },
    isResponsibleAccount() {
      if (this.$route.params.idClient) {
        return !(this.$store.getters['NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO'].is_responsible === this.currentUser.user_id || this.$store.getters['NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO'].is_responsible === null);
      }
      return false;
    },
  },
};
</script>

